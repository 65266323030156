<template>
  <div class="columns no-margin">
    <!-- <div class="columns no-margin"> -->
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light is-uppercase">{{ headerText }} {{ tipe }}</p>
        </header>
        <div class="card-content events-card">
          <!-- perlu diganti dengan div spacing -->
          <div class="field is-horizontal">
            <div class="field-label">
              <!-- Left empty for spacing -->
            </div>
          </div>

          <b-field v-if="isAdmin && isCreate && tipe !== 'admin'">
            <b-select v-model="tipe" required>
              <option value="dosen">Dosen</option>
              <option value="mahasiswa">Mahasiswa</option>
            </b-select>
          </b-field>

          <b-field
            label="Nama"
            custom-class="is-small"
            :type="{'is-danger': errorMap.nama}"
            :message="errorMap.nama"
          >
            <b-input ref="firstInput" v-model="anggota.nama" required @input="validateInput('nama')"></b-input>
          </b-field>
          <b-field label="Inisial" custom-class="is-small notrequired"
            :type="{'is-danger': errorMap.inisial}"
            :message="errorMap.inisial">
            <b-input v-model="anggota.inisial"
              @input="validateInput('inisial')"></b-input>
          </b-field>

          <slot />
          <!-- untuk diisi dengan field preceptor atau mahasiswa -->

          <b-field
            label="Email"
            custom-class="is-small"
            :type="{'is-danger': errorMap.email || errorMap.user }"
            :message="errorMap.email || errorMap.user"
          >
            <b-input
              v-model="anggota.user.email"
              type="email"
              required
              @input="validateInput('email')"
            ></b-input>
          </b-field>
          <b-field
            label="No hp"
            custom-class="is-small notrequired"
            :type="{'is-danger': errorMap.no_hp}"
            :message="errorMap.no_hp"
          >
            <b-input v-model="anggota.no_hp"
              @input="validateInput('no_hp')"></b-input>
          </b-field>
          <b-field
            label="Alamat"
            custom-class="is-small notrequired"
            :type="{'is-danger': errorMap.alamat}"
            :message="errorMap.alamat"
          >
            <b-input v-model="anggota.alamat" type="textarea"
              @input="validateInput('alamat')"></b-input>
          </b-field>
          <b-field label="Catatan" custom-class="is-small notrequired" 
            :type="{'is-danger': errorMap.catatan}"
            :message="errorMap.catatan">
            <b-input v-model="anggota.catatan" type="textarea"
              @input="validateInput('catatan')"></b-input>
          </b-field>

          <!-- perlu diganti dengan div spacing -->
          <div class="field is-horizontal">
            <div class="field-label">
              <!-- Left empty for spacing -->
            </div>
          </div>
          <div class="buttons">
            <b-button class="is-primary has-text-weight-semibold" @click.stop="cancel">Batal</b-button>
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject"
            >Simpan</b-button>
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import APP_CONFIG from "@/apps/core/modules/config.js";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import { mapGetters } from "vuex";


export default {
  name: "CommonAnggotaCreateUpdate",
  props: [ "headerText", "anggotaMdl" ],
  mixins: [createUpdateMixin],
  data() {
    this.objectMdl = this.anggotaMdl;  // alias yg digunakan di cuMixin
    return this.anggotaMdl.getObservables();
  },
  computed: {
    ...mapGetters("accounts", ["isAdmin"])
  },
  methods: {
    saveObject() {
      if (this.isCreate) {
        let apiURL = `${APP_CONFIG.baseAPIURL}/anggota/`;
        if (this.tipe !== "admin") {
          apiURL = `${apiURL}${this.tipe}/`;
        }
        this.anggotaMdl.setApiURL(apiURL);
      }
      this.anggotaMdl.save(this.saveContext, () => {
        this.$router.go(-1);
      });
    }
  },
  watch: {
    anggota: {
      // anggota berubah, maka tombol simpan bisa aktif
      handler(newValue, oldValue) {
        if (!this.anggotaMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true
    },
    tipe(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$router.replace({ name: `${newValue}-add` });
      }
    }
  }
};
</script>
